import React from 'react';
import './index.css'; // Ensure the CSS file is linked
import listings from "../../../jobListingData"

function JobListings() {

    const clickedListing = (listing) => {
        window.location.href = "/careers/" + listing.id
    }

    return (
        <>
            <div className="joblisting-top-spacer"></div> {/* Spacer to handle overflow from the careers section */}
            <div className="joblisting-section">
                <div class="joblisting-section-container">
                    {listings.map((listing, index) => (
                        <div class="joblisting-item" onClick={() => clickedListing(listing)}>
                            <div class="joblisting-item-top">
                                <article class="main">
                                    <img src={listing.logo} alt="Logo" class="rounded-circle" />
                                </article>
                                <aside class="aside aside-1">
                                    <h3>{listing.title}</h3>
                                    <p className='country'>{listing.location}</p>
                                </aside>
                            </div>

                            <p>{listing.small_description}</p>

                            <div class="button-row">
                                {listing.tags.map((tag, tagIndex) => (
                                    <button className="btn" key={tagIndex}>{tag}</button>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default JobListings;
