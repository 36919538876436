import { useLocation, useParams } from 'react-router-dom';
import Header from '../../Components/Header';
import CareerDetails from './CareerDetails';
import CareerHero from './CareerHero';
import listings from '../../jobListingData';
import Footer from '../../Components/Footer';

function Career() {
  const { id } = useParams();
  const job = listings.find(listing => listing.id === id);
  
  return (
    <>
      <Header/>
      <CareerHero listing={job}/>
      <CareerDetails listing={job}/>
      <Footer/>
    </>
  );
}

export default Career;
