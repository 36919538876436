import { height } from '@fortawesome/free-brands-svg-icons/fa42Group';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import CareersHero from './CareersHero';
import JobListings from "./JobListings";
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { pageVariants, pageTransition } from '../../variants';


function Careers() {
  const navigate = useNavigate();

  const didClickSchedule = () => {
    navigate("/for-companies");
  }

  return (
    <>
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}
      >
        <Header type={2} didClickSchedule={didClickSchedule} />
        <CareersHero />
        <JobListings />
        <Footer />
      </motion.div>
    </>
  );
}

export default Careers;
