// General
export const pageVariants = {
    initial: {
      opacity: 0,
      y: '100vh',
    },
    in: {
      opacity: 1,
      y: 0,
    },
    out: {
      opacity: 0,
      y: '-100vh',
    },
  };
  
  export const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5,
  };


  // Home
  export const homePageAnimationVariant = {
    initial: {
      opacity: 0,
      scale:0.5
    },
    in: {
      opacity: 1,
      scale:1
    },
    out: {
      opacity: 0,
      scale: 0
    },
  };
  
  export const homePageTransition = {
    type: 'tween',
    ease: [0, 0.71, 0.2, 1.01],
    delay:0.3,
    duration: 1.5,
  };
 
  // OrganisationChoice
  export const organisationChoicePageAnimationVariant = {
    initial: {
      opacity: 0,
      x: '100vw',
    },
    in: {
      opacity: 1,
      x: 0,
    },
    out: {
      opacity: 0,
      x: '-100vw',
    },
  };
  
  export const organisationChoicePageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5,
  };