import styles from './index.module.scss'

function WhatWeOffer() {
    return (
        <section className={styles.whatweofferSection}>
            <div className={styles.title}>
                <h2>We offer you...</h2>
            </div>
            <div className={styles.container}>
                <div className={styles.left}>
                    <h2>
                        Custom Solutions for every challenge
                    </h2>
                    <p>
                    At MDC Lab, we specialize in building custom digital products tailored to your business needs. From fully fledged platforms to bespoke websites, we bring your vision to life with precision and efficiency.
                    </p>
                </div>
                <div className={styles.right}>
                    <div className={styles.line}></div>
                    <div className={styles.bubble}>1</div>
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.right}>
                    <div className={styles.bubble}>2</div>
                    <div className={styles.line}></div>
                </div>
                <div className={styles.left}>
                    <h2>
                        A collaborative team
                    </h2>
                    <p>
                        We work in close collaboration with you, ensuring constant communication and iterative development to deliver a product that meets—and exceeds—your expectations.
                    </p>
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.left}>
                    <h2>
                        Clear oversight
                    </h2>
                    <p>
                        With structured production cycles, clear timelines, and regular review sessions, we make sure your project stays on track and evolves as needed.
                    </p>
                </div>
                <div className={styles.right}>
                    <div className={styles.line}></div>
                    <div className={styles.bubble}>3</div>
                </div>
            </div>
        </section>
    );
}

export default WhatWeOffer;