// NextSection.js
import React from 'react';
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

function TechStacksHowItWorks() {
    return (
        <section className="techstacks-how-it-works">
            <div className="techstacks-how-it-works-content-container">
                <div className="techstacks-how-it-works-left-content">
                    <div className="techstacks-how-it-works-left-content-container">
                        <p className="techstacks-how-it-works-title">Tell us what you need<span className="orange-dot">.</span></p>
                    </div>
                </div>
                <div className="techstacks-how-it-works-step-box"></div>
            </div>
        </section>
    );
}

export default TechStacksHowItWorks;