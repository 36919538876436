import styles from './index.module.scss'
import { motion } from 'framer-motion';

function companiesHero() {
    return (
        <section className={styles.companiesHero}>
            <div className={styles.content}>
                <div className={styles.taglines}>
                    <h1 className={styles.main}>
                        <span><span className={styles.so}>So...</span> You’re a company,</span>
                    </h1>
                    <h1 className={styles.main}>
                        and you need software built?
                    </h1>
                </div>
                <div className={styles.scrollIndicator} whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.9 }}>
                    <motion.p
                        animate={{
                            rotate: [0, -5, 5, -5, 0],
                            scale: [1,1.2,1]
                        }}
                        transition={{
                            duration: 1,
                            ease: "easeInOut",
                            times: [0, 0.2, 0.5, 0.8, 1],
                            repeat: Infinity,
                            repeatDelay: 4
                        }}
                    >
                        Here’s how we can help!
                    </motion.p>
                    <motion.div
                        className={styles.arrow}
                        animate={{
                            y: ["0", "-10px", "0", "-10px", "0", "-10px", "0"],
                        }}
                        transition={{
                            duration: 2,
                            ease: "easeInOut",
                            times: [0, 0.2, 0.5, 0.8, 1],
                            repeat: Infinity,
                            repeatDelay: 1
                        }}
                    />
                    {/* <div className={styles.arrow}></div> */}
                </div>
            </div>
        </section>
    );
}

export default companiesHero;