import styles from './index.module.scss'

function WhyChooseUs() {
    return (
        <section className={styles.whychooseusSection}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <h2>Why choose MDC Lab?</h2>
                </div>
                <div className={styles.items}>
                    <div className={styles.item}>
                        <div className={styles.tagline}>
                            <p>Client-Centric Approach</p>
                        </div>
                        <div className={styles.details}>
                            <p>We build a dedicated team around your specific needs, ensuring the right expertise is applied to every aspect of your project.</p>
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.tagline}>
                            <p>Agile Style</p>
                        </div>
                        <div className={styles.details}>
                            <p>Our agile methodology ensures flexibility and adaptability, allowing for continuous improvement and alignment with your vision.</p>
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.tagline}>
                            <p>Product Focused</p>
                        </div>
                        <div className={styles.details}>
                            <p>Your success is our success. We strive to deliver error-free, user-friendly products that get into your users’ hands seamlessly.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default WhyChooseUs;