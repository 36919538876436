// NextSection.js
import React from 'react';
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

function CareerHowItWorks() {
    return (
        <section className="career-how-it-works">
            <div className="career-how-it-works-content-container">
                <div className="career-how-it-works-left-content">
                    <div className="career-how-it-works-left-content-container">
                        <p className="career-how-it-works-title">Join Our Talent Pool</p>
                        <p className="career-how-it-works-description">We’re actively seeking the best people to join our expanding community.  Apply with us to see available opportunities and learn about roles that might suit your strengths.  We’ll even reach out when a role matches your skills and passions. Through MyDevConnect, you’ll discover new opportunities to shape your dynamic future, whether you’re experienced or aspiring we’ll be sure to have a remote role that’s a great fit.</p>
                    </div>
                </div>
                <div className="career-how-it-works-step-box">
                    
                </div>
            </div>
        </section>
    );
}

export default CareerHowItWorks;