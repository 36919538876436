import styles from './index.module.scss'
import ABinBev from '../../../images/partnerLogos/ABinBev.png';
import SpotSale from '../../../images/partnerLogos/SpotSale.png';
import PCX from '../../../images/partnerLogos/PCX.png';
import MyDevConnect from '../../../images/partnerLogos/MyDevConnect.png';
import Bees from '../../../images/partnerLogos/Bees.png';
import RoT from '../../../images/partnerLogos/RoT.png';

function Partners() {
    return (
        <section className={styles.partnersSection}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <h1>Companies we’ve supported</h1>
                </div>
                <div className={styles.companies}>
                    <div className={styles.company}>
                        <img src={Bees}/>
                    </div>
                    <div className={styles.company}>
                        <img src={ABinBev}/>
                    </div>
                    <div className={styles.company}>
                        <img src={RoT}/>
                    </div>
                </div>
                <div className={styles.companies}>
                    <div className={styles.company}>
                        <div className={styles.stagger}></div>
                    </div>
                    <div className={styles.company}>
                        <img src={SpotSale}/>
                    </div>
                    <div className={styles.company}>
                        <img src={PCX}/>
                    </div>
                    <div className={styles.company}>
                        <div className={styles.stagger}></div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Partners;