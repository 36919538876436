import React from 'react';
import styles from './index.module.scss';
import Logo from '../../images/logos/MDCLabLogo.png'
import WhiteLogo from '../../images/logos/MDCLabLogoWhite.png'
import ColorLogo from '../../images/logos/MDCLabLogoColor.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';

function Header({ type, didClickSchedule }) {
    const location = useLocation();
    const isActive = (path) => location.pathname === path;

    const headerTypes = [
        {
            logo: Logo,
            overlay: false
        },{
            logo: Logo,
            overlay: false
        },{
            logo: WhiteLogo,
            overlay: true
        }
    ]

    return (
        <header className={headerTypes[type].overlay ? styles.overlayHeader : styles.header}>
            {(typeof window != "undefined") && <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js"></script>}
            <div className={styles.headerMainContainer}>
                <div className={styles.logo}>
                    <a href="/">
                        <img src={headerTypes[type].logo} alt="Logo" style={{ height: '40px' }} />
                    </a>
                </div>
                <div className={styles.navContainer}>
                    <nav>
                        <a href="/" className={isActive('/') ? styles.active : ''}>Home</a>
                        <a href="/tech-stacks" className={isActive('/tech-stacks') ? styles.active : ''}>Stacks</a>
                        <a href="/careers" className={isActive('/careers') ? styles.active : ''}>Careers</a>
                        <a href="https://platform.mydevconnect.com" target='_blank'>Sign in</a>
                    </nav>
                </div>
                <div className={styles.callAction}>
                    <button onClick={didClickSchedule}>
                        <span className={styles.scheduleText}>Schedule a Call</span>
                        <span className={styles.scheduleIcon}><FontAwesomeIcon icon={faPhone} /></span>
                    </button>
                </div>
            </div>
            <div className={styles.mobileNavContainer}>
                <nav>
                    <a href="/" className={isActive('/') ? styles.active : ''}>Home</a>
                    <a href="/tech-stacks" className={isActive('/tech-stacks') ? styles.active : ''}>Stacks</a>
                    <a href="/careers" className={isActive('/careers') ? styles.active : ''}>Careers</a>
                    <a href="https://platform.mydevconnect.com" target='_blank'>Sign in</a>
                </nav>
            </div>
        </header>
    );
}

export default Header;