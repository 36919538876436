// Hero.js
import React, { useState } from 'react';
import './index.css'
import TechStacksHowItWorks from './TechStacks-How-It-Works';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faPlusCircle, faSeedling, faUserGroup } from '@fortawesome/free-solid-svg-icons';

function TechStacksHero() {
    const [isVisible, setIsVisible] = useState(false)
    const [buttonText, setButtonText] = useState()

    return (
        <section className="techstacks-hero">
            <div className="techstacks-hero-top-spacer"></div>
            <div className="techstacks-hero-content">
                <div className="techstacks-hero-left-container">
                    <div className="techstacks-hero-taglines">
                        <h1 className="techstacks-hero-main-tagline">
                            <span></span><br />
                            <span>Tech Stacks of</span><br />
                            <span>Your Choosing</span><span className="orange-dot"> .</span><br />
                        </h1>
                        <p className="techstacks-hero-sub-tagline">Tell us about your requirements.  What tech stacks are you using? What would you like to use? Is there a process, product, technology or otherwise that you can identify requirements for? This is a starting point for our conversation allowing you to define your requirements</p>
                    </div>
                    <div className="techstacks-hero-button-info">
                        <div className={isVisible ? "techstacks-hero-button-info-container visible" : "techstacks-hero-button-info-container"}>
                            <p>{buttonText}</p>
                        </div>
                    </div>
                </div>
                <div className="techstacks-hero-right-container">
                </div>
            </div>
            <div className='techstacks-hero-bottom-content'>
                <TechStacksHowItWorks />
            </div> 
        </section>
    );
}

export default TechStacksHero;