import "./index.css"
import React, { useState, useEffect, useRef } from 'react';

const optionsList = [
    "Slack", "Jira", "Bitbucket", "Confluence", "Express.js", "GraphQL", "SQL", "HTML", "Flask",
    "Scrapy", "Django", "PostgreSQL", "RESTful APIs", "Pytest", "Celery", "Bootstrap", "Vuex", "Ubuntu",
    "Tailwind", "MySQL", "Stripe", "Keras", "Kotlin", "Amazon S3", "Jupyter", "Gatsby", "Redux",
    "Appium", "Selenium", "AWS Lambda", "Symphony", "Wordpress", "Amazon API Gateway", "AWS Glue",
    "Redshift", "Apache Kafka", "Tableau", "Jenkins", "Linux", "Visual Studio", "C++14", "Apache Maven",
    "Bash", "Terraform", "Arduino", "QA Automation", "Adobe XD", "Adobe Illustrator", "Adobe Photoshop",
    "Sketch", "ios", "Zeplin", "InVision", "Figma", "Axure RP Pro", "Python3", "Azure Synapse",
    "PySpark", "Cloud Security", "Cloud Infrastructure", "Database Design", "Cassandra", "Golang", "C++",
    "TensorFlow", "NumPy", "SciPy", "Pandas", "Hadoop", "Apache Hive", "ITIL 4", "Three.js", "Socket.IO",
    "PyTorch", "Latex", "Stable Diffusion"
];

const TechStacksOptionsDropdown = ({selectedOptions, onSelectionChange}) => {
    const [inputValue, setInputValue] = useState('');
    const [filteredOptions, setFilteredOptions] = useState(optionsList);
    const [showDropdown, setShowDropdown] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        setFilteredOptions(
            optionsList.filter(option =>
                option.toLowerCase().includes(inputValue.toLowerCase())
            )
        );
    }, [inputValue]);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        setShowDropdown(true);
    };

    const handleOptionClick = (option) => {
        if (!selectedOptions.includes(option)) {
            const newSelectedOptions = [...selectedOptions, option]
            setInputValue('');
            setShowDropdown(false);
            onSelectionChange(newSelectedOptions);
        }
    };

    const handleRemoveOption = (optionToRemove) => {
        const newSelectedOptions = selectedOptions.filter(option => option !== optionToRemove)
        onSelectionChange(newSelectedOptions);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Backspace' && inputValue === '') {
            const newSelectedOptions = selectedOptions.slice(0, -1)
            onSelectionChange(newSelectedOptions);
        }
    };

    return (
        <div className="techstacksdropdown-container">
            <div className="techstacksdropdown-input-container" onClick={() => inputRef.current.focus()}>
                {selectedOptions.map((option, index) => (
                    <div className="techstacksdropdown-input-option" key={index}>
                        {option}
                        <button className="techstacksdropdown-input-option-remove-button" onClick={() => handleRemoveOption(option)}>x</button>
                    </div>
                ))}
                <input className="techstacksdropdown-input"
                    ref={inputRef}
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    onFocus={() => setShowDropdown(true)}
                    onBlur={() => setShowDropdown(false)}
                    placeholder="Enter other skills"
                />
            </div>
            {showDropdown && filteredOptions.length > 0 && (
                <div className="techstacksdropdown-input-drop-menu">
                    {filteredOptions.map((option, index) => (
                        <div className="techstacksdropdown-input-drop-menu-item" key={index} onMouseDown={() => handleOptionClick(option)}>
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default TechStacksOptionsDropdown;