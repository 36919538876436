// Hero.js
import React, { useState } from 'react';
import './index.css'
import CareersHowItWorks from './Careers-How-It-Works';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faPlusCircle, faSeedling, faUserGroup } from '@fortawesome/free-solid-svg-icons';

function CareersHero() {
    const [isVisible, setIsVisible] = useState(false)
    const [buttonText, setButtonText] = useState()

    return (
        <section className="careers-hero">
            <div className="careers-hero-top-spacer"></div>
            <div className="careers-hero-content">
                <div className="careers-hero-left-container">
                    <div className="careers-hero-taglines">
                        <h1 className="careers-hero-main-tagline">
                            <span></span><br />
                            <span>Join Our</span><br />
                            <span>Team</span><span className="orange-dot"> .</span><br />
                            
                        </h1>
                        <p className="careers-hero-sub-tagline">Where Talents Thrive and Futures Flourish. At <br/>MyDevConnect, we believe in cultivating a dynamic <br /> environment that encourages innovation and fosters <br /> personal growth. Join our team and embark on a journey <br /> where your skills are not just valued but empowered.</p>
                    </div>
                    <div className="careers-hero-button-info">
                        <div className={isVisible ? "careers-hero-button-info-container visible" : "careers-hero-button-info-container"}>
                            <p>{buttonText}</p>
                        </div>
                    </div>
                </div>
                <div className="careers-hero-right-container">
                </div>
            </div>
            <div className='careers-hero-bottom-content'>
                <CareersHowItWorks />
            </div> 
        </section>
    );
}

export default CareersHero;