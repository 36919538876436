import { InlineWidget } from 'react-calendly';
import styles from './index.module.scss'
import { motion } from 'framer-motion';
import { useState } from 'react';
import { OrbitProgress } from 'react-loading-indicators';

function ReadyToTalk({scheduleRef}) {
    const [email, setEmail] = useState()
    const [isLoading, setIsLoading] = useState()
    const [formSuccess, setFormSuccess] = useState()
    const [formError, setFormError] = useState(false)

    const enquire = () => {
        setIsLoading(true)
        var params = {
            email: email
        }

        fetch(`https://w2xjmr0ofh.execute-api.eu-west-2.amazonaws.com/production/enquiry/send`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams(params)
        })
            .then((res) => res.json())
            .then((response) => {
                console.log(response.data)
                if (response == 200) {
                    setFormSuccess(true)
                    setFormError(false)
                } else {
                    setFormSuccess(false)
                    setFormError(true)
                }
                setIsLoading(false)
                setEmail()
            })
            .catch((err) => {
                console.log(err.message);
                setIsLoading(false)
                setFormSuccess(false)
                setFormError(true)
            });
    }

    return (
        <section ref={scheduleRef} className={styles.readytotalkSection}>
            <div className={styles.title}>
                <h2>Ready to talk?</h2>
            </div>
            <div className={styles.container}>
                <div className={styles.calendar}>
                    <h2 className={styles.title}>Schedule a call</h2>
                    <p className={styles.subtitle}>30 minute consultation</p>
                    <InlineWidget className={styles.calendar} url="https://calendly.com/mdclab/30min" pageSettings={{
                        hideGdprBanner: true,
                        hideEventTypeDetails: true,
                    }} styles={{
                        height: '800px'
                    }} />
                    {/* <iframe src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ0x5O4ljOL5cAbY-GuuZAu-2t9wZiFRwMnHAjqS4ZdAEHh2OdJQemE2BhukrMF-uCBogt-vmzWV?gv=true" style={{ border: "0" }} width="100%" height="600" frameborder="0"></iframe> */}
                </div>
                <div className={styles.enquire}>
                    <h2 className={styles.title}>Or...</h2>
                    <p className={styles.subtitle}>Leave your email and we'll get back to you</p>
                    <div className={styles.form}>
                        <div className={styles.formContainer}>
                            {
                                isLoading ? (
                                    <OrbitProgress color="#888" size="small" text="" textColor="" />
                                ) : (
                                    <form onSubmit={enquire}>
                                        <input type="email" placeholder="Your email" onChange={(e) => setEmail(e.target.value)} required />
                                        <button type="submit">Enquire</button>
                                    </form>
                                )
                            }
                        </div>
                    </div>
                    <p>
                        {formSuccess && <span className={formSuccess ? `${styles.success} ${styles.visible}` : `${styles.success}`}>Done! Sending you more info now</span>}
                        {formError && <span className={formError ? `${styles.error} ${styles.visible}` : `${styles.error}`}>Something went wrong, try again</span>}
                    </p>
                </div>
            </div>
        </section>
    );
}

export default ReadyToTalk;