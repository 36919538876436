import jobLogo from "./images/logos/color_small.png";

const listings = [
    {
        "id": "python-24May24-0001",
        "logo": jobLogo,
        "title": "Python Developer",
        "display_titles": [
            "Python",
            "Developer"
        ],
        "location": "Remote",
        "small_description": "Are you a Python expert, passionate about building strong back-end solutions? MyDevConnect, a leader in Tech Alchemy, is searching for skilled Python Developers to enhance our team. Whether you thrive in the dynamic environment of our Accra, we're looking for individuals skilled in server-side...",
        "description": "Are you a Python expert, passionate about building strong back-end solutions? MyDevConnect, a leader in Tech Alchemy, is searching for skilled Python Developers to enhance our team. Whether you thrive in the dynamic environment of our Accra, Ghana office or prefer the flexibility of remote work from Kigali, Rwanda, we're looking for individuals skilled in server-side logic, database management, and API development. If you excel in implementing security measures, optimizing performance, and facilitating integrations, and if you enjoy collaborating with front-end developers to deliver comprehensive solutions, we welcome you to join us. MyDevConnect's Python Developers empower applications with efficiency and functionality, ensuring smooth data flow.",
        "tags": [
            "Developer",
            "Full-Time",
            "Remote",
            "Urgent",
        ],
        "responsibilities": [
            {
                "title": "Backend Logic Implementation",
                "description": "Develop and deploy server-side logic, databases, APIs, and other backend processes to facilitate smooth data management and application operation."
            },
            {
                "title": "Database Administration",
                "description": "Create and manage databases, handling schema setup, optimization, and efficient data retrieval methods."
            },
            {
                "title": "API Construction",
                "description": "Build robust and comprehensively documented APIs to facilitate seamless communication between frontend and backend systems."
            },
            {
                "title": "Security Implementation",
                "description": "Enforce stringent security measures, encryption, and authentication protocols to protect sensitive data and prevent unauthorized access."
            },
            {
                "title": "Performance Enhancement",
                "description": "Improve server and database performance by identifying bottlenecks and implementing solutions for faster speeds and scalability."
            },
            {
                "title": "Code Maintenance",
                "description": "Debug, troubleshoot, and upkeep the existing codebase to ensure its relevance and functionality over time."
            },
            {
                "title": "Version Control Management",
                "description": "Employ version control systems like Git to oversee code changes, collaborate with team members, and track revisions effectively."
            },
            {
                "title": "Server Environment Setup",
                "description": "Configure and manage server environments, handling deployment, scaling, and ensuring continuous application availability."
            },
            {
                "title": "Integration",
                "description": "Integrate external services, tools, and APIs to extend application functionality and enhance user experience."
            },
            {
                "title": "Team Collaboration",
                "description": "Work closely with frontend developers, designers, and team members to deliver comprehensive end-to-end solutions and achieve project goals collaboratively."
            }
        ],
        "skills_experience": [
            {
                "title": "Python Proficiency",
                "description": "Mastering Python for efficient web application development."
            },
            {
                "title": "Server Environment Skills",
                "description": "Competency in Apache, Nginx, or Microsoft IIS for hosting web apps."
            },
            {
                "title": "Database Management",
                "description": "Managing MySQL, PostgreSQL, MongoDB, and Redis for data efficiency."
            },
            {
                "title": "API Tool Familiarity",
                "description": "Using Postman or Swagger for seamless API integration."
            },
            {
                "title": "Version Control Mastery",
                "description": "Proficient in Git for collaborative development."
            },
            {
                "title": "Command-Line Interface Proficiency",
                "description": "Skill in CLI for streamlined operations."
            },
            {
                "title": "Deployment Knowledge",
                "description": "Understanding Docker, Kubernetes, Heroku, AWS, or Azure."
            },
            {
                "title": "Security Awareness",
                "description": "Implementing encryption, authentication, and vulnerability assessment."
            },
            {
                "title": "Caching Techniques",
                "description": "Using Memcached or Redis for enhanced performance."
            },
            {
                "title": "Framework Proficiency",
                "description": "Expertise in Django, Flask, etc., for rapid development."
            },
            {
                "title": "Serverless Computing Awareness",
                "description": "Basic understanding of serverless platforms."
            },
            {
                "title": "Debugging Skills",
                "description": "Familiar with tools for prompt issue resolution."
            },
            {
                "title": "Build Automation Proficiency",
                "description": "Efficiency with Grunt, Gulp, or Webpack for automation."
            },
            {
                "title": "CI/CD Knowledge",
                "description": "Basic understanding of Jenkins, Travis CI, or CircleCI."
            },
            {
                "title": "Monitoring and Logging Understanding",
                "description": "Proficiency in New Relic, Datadog, or ELK stack."
            },
            {
                "title": "Shell Scripting Basics",
                "description": "Knowledge of Bash for task automation."
            },
            {
                "title": "API Documentation Familiarity",
                "description": "Using Swagger UI or Postman for clear API documentation."
            }
        ]
    },{
        "id": "projectmanager-26May24-0002",
        "logo": jobLogo,
        "title": "Lead Architect",
        "display_titles": [
            "Lead",
            "Architect"
        ],
        "location": "Remote",
        "small_description": "As an Integrated Lead Architect, your role involves overseeing creative and production procedures, guiding projects from inception to completion. Working closely with various teams within the organization, your aim is to achieve successful project results aligned with client standards.",
        "description": "As an Integrated Lead Architect, your role involves overseeing creative and production procedures, guiding projects from inception to completion. Working closely with various teams within the organization, your aim is to achieve successful project results aligned with client standards.",
        "tags": [
            "Manager",
            "Full-Time",
            "Remote",
            "Urgent",
        ],
        "responsibilities": [
            {
                "title": "Backend Logic Implementation",
                "description": "Develop and deploy server-side logic, databases, APIs, and other backend processes to facilitate smooth data management and application operation."
            },
            {
                "title": "Database Administration",
                "description": "Create and manage databases, handling schema setup, optimization, and efficient data retrieval methods."
            },
            {
                "title": "API Construction",
                "description": "Build robust and comprehensively documented APIs to facilitate seamless communication between frontend and backend systems."
            },
            {
                "title": "Security Implementation",
                "description": "Enforce stringent security measures, encryption, and authentication protocols to protect sensitive data and prevent unauthorized access."
            },
            {
                "title": "Performance Enhancement",
                "description": "Improve server and database performance by identifying bottlenecks and implementing solutions for faster speeds and scalability."
            },
            {
                "title": "Code Maintenance",
                "description": "Debug, troubleshoot, and upkeep the existing codebase to ensure its relevance and functionality over time."
            },
            {
                "title": "Version Control Management",
                "description": "Employ version control systems like Git to oversee code changes, collaborate with team members, and track revisions effectively."
            },
            {
                "title": "Server Environment Setup",
                "description": "Configure and manage server environments, handling deployment, scaling, and ensuring continuous application availability."
            },
            {
                "title": "Integration",
                "description": "Integrate external services, tools, and APIs to extend application functionality and enhance user experience."
            },
            {
                "title": "Team Collaboration",
                "description": "Work closely with frontend developers, designers, and team members to deliver comprehensive end-to-end solutions and achieve project goals collaboratively."
            }
        ],
        "skills_experience": [
            {
                "title": "Python Proficiency",
                "description": "Mastering Python for efficient web application development."
            },
            {
                "title": "Server Environment Skills",
                "description": "Competency in Apache, Nginx, or Microsoft IIS for hosting web apps."
            },
            {
                "title": "Database Management",
                "description": "Managing MySQL, PostgreSQL, MongoDB, and Redis for data efficiency."
            },
            {
                "title": "API Tool Familiarity",
                "description": "Using Postman or Swagger for seamless API integration."
            },
            {
                "title": "Version Control Mastery",
                "description": "Proficient in Git for collaborative development."
            },
            {
                "title": "Command-Line Interface Proficiency",
                "description": "Skill in CLI for streamlined operations."
            },
            {
                "title": "Deployment Knowledge",
                "description": "Understanding Docker, Kubernetes, Heroku, AWS, or Azure."
            },
            {
                "title": "Security Awareness",
                "description": "Implementing encryption, authentication, and vulnerability assessment."
            },
            {
                "title": "Caching Techniques",
                "description": "Using Memcached or Redis for enhanced performance."
            },
            {
                "title": "Framework Proficiency",
                "description": "Expertise in Django, Flask, etc., for rapid development."
            },
            {
                "title": "Serverless Computing Awareness",
                "description": "Basic understanding of serverless platforms."
            },
            {
                "title": "Debugging Skills",
                "description": "Familiar with tools for prompt issue resolution."
            },
            {
                "title": "Build Automation Proficiency",
                "description": "Efficiency with Grunt, Gulp, or Webpack for automation."
            },
            {
                "title": "CI/CD Knowledge",
                "description": "Basic understanding of Jenkins, Travis CI, or CircleCI."
            },
            {
                "title": "Monitoring and Logging Understanding",
                "description": "Proficiency in New Relic, Datadog, or ELK stack."
            },
            {
                "title": "Shell Scripting Basics",
                "description": "Knowledge of Bash for task automation."
            },
            {
                "title": "API Documentation Familiarity",
                "description": "Using Swagger UI or Postman for clear API documentation."
            }
        ]
    }
]

export default listings;