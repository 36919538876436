import React, { useState } from 'react';
import "./index.css";
import NotSure from "../../../../images/techstacks/Not_sure,_need_advice.png";
import ReactLogo from "../../../../images/techstacks/React.png";
import Next from "../../../../images/techstacks/Next.js.png";
import Vue from "../../../../images/techstacks/Vue.js.png";
import Angular from "../../../../images/techstacks/Angular.png";
import Svelte from "../../../../images/techstacks/Svelte.png";
import JavaScript from "../../../../images/techstacks/JavaScript.png";
import TypeScript from "../../../../images/techstacks/TypeScript.png";
import Node from "../../../../images/techstacks/Node.js.png";
import Python from "../../../../images/techstacks/Python.png";
import CSharp from "../../../../images/techstacks/C#.png";
import Ruby_on_Rails from "../../../../images/techstacks/Ruby_on_Rails.png";
import Data_Engineering from "../../../../images/techstacks/Data_Engineering.png";
import AWS from "../../../../images/techstacks/AWS.png";
import Azure from "../../../../images/techstacks/Azure.png";
import Google_Cloud from "../../../../images/techstacks/Google_Cloud.png";
import Docker from "../../../../images/techstacks/Docker.png";
import DevOps from "../../../../images/techstacks/DevOps.png";

const TechStacksMainOptions = ({ selectedOptions, onSelectionChange }) => {

  const optionsList = [
    { "title": "React", "logo": ReactLogo },
    { "title": "Next.js", "logo": Next },
    { "title": "Vue.js", "logo": Vue },
    { "title": "Angular", "logo": Angular },
    { "title": "Svelte", "logo": Svelte },
    { "title": "JavaScript", "logo": JavaScript },
    { "title": "TypeScript", "logo": TypeScript },
    { "title": "Node.js", "logo": Node },
    { "title": "Python", "logo": Python },
    { "title": "C#", "logo": CSharp },
    { "title": "Ruby on Rails", "logo": Ruby_on_Rails },
    { "title": "Data", "logo": Data_Engineering },
    { "title": "AWS", "logo": AWS },
    { "title": "Azure", "logo": Azure },
    { "title": "Google Cloud", "logo": Google_Cloud },
    { "title": "Docker", "logo": Docker },
    { "title": "DevOps", "logo": DevOps },
    { "title": "Other/Not sure", "logo": NotSure }
  ];

  const toggleSelection = (title) => {
    const newSelectedOptions = selectedOptions.includes(title)
      ? selectedOptions.filter(item => item !== title)
      : [...selectedOptions, title];

    onSelectionChange(newSelectedOptions);
  };

  return (
    <div className="techstacksmainoptions-container">
      {optionsList.map((item, index) => (
        <div 
          key={index}
          className={`techstacksmainoptions-box ${selectedOptions.includes(item.title) ? 'selected' : ''}`}
          onClick={() => toggleSelection(item.title)}
        >
          <img src={item.logo} alt={item.title} />
          <div className="techstacksmainoptions-name">{item.title}</div>
        </div>
      ))}
    </div>
  );
};

export default TechStacksMainOptions;