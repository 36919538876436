// Hero.js
import React, { useState } from 'react';
import './index.css'
import CareerHeroStepDown from './CareerHero-StepDown';

function CareerHero({listing}) {
    const [isVisible, setIsVisible] = useState(false)
    const [buttonText, setButtonText] = useState()

    return (
        <section className="career-hero">
            <div className="career-hero-top-spacer"></div>
            <div className="career-hero-content">
                <div className="career-hero-left-container">
                    <div className="career-hero-taglines">
                        <h1 className="career-hero-main-tagline">
                            <span></span><br />
                            <span>{listing.display_titles[0]}</span><br />
                            <span>{listing.display_titles[1]}<span className="orange-dot"> .</span></span><br />
                        </h1>
                        <p className="career-hero-sub-tagline-role-title-description">
                            Job Description
                        </p>
                        <p className="career-hero-sub-tagline-role-description">
                            {listing.description}
                        </p>
                    </div>
                    <div className="career-hero-button-info">
                        <div className={isVisible ? "career-hero-button-info-container visible" : "career-hero-button-info-container"}>
                            <p>{buttonText}</p>
                        </div>
                    </div>
                </div>
                <div className="career-hero-right-container">
                </div>
            </div>
            <div className="career-hero-bottom-content">
                <CareerHeroStepDown />
            </div> 
        </section>
    );
}

export default CareerHero;