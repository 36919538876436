// WhyUsSection.js
import React, { useEffect, useState } from 'react';
import './index.css'; // Ensure the CSS file is linked
import TechStacksOptionsDropdown from "./TechStacksDropdown"
import TechStacksMainOptions from './TechStacksMainOptions';
import { OrbitProgress } from 'react-loading-indicators';

function TechStacksOptions() {
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [phone, setPhone] = useState()
    const [information, setInformation] = useState()
    const [mainStacks, setMainStacks] = useState([]);
    const [subStacks, setSubStacks] = useState([])
    const [isLoading, setIsLoading] = useState()
    const [formSuccess, setFormSuccess] = useState()
    const [formError, setFormError] = useState(false)

    const handleMainSelectionChange = (newSelectedOptions) => {
        setMainStacks(newSelectedOptions);
    };

    const handleSubSelectionChange = (newSelectedOptions) => {
        setSubStacks(newSelectedOptions);
    };

    const enquire = (event) => {
        event.preventDefault();
        setIsLoading(true)
        var params = {
            name: name,
            email: email,
            phone: phone,
            information: information,
            main_stacks: mainStacks,
            sub_stacks: subStacks
        }

        fetch(`https://w2xjmr0ofh.execute-api.eu-west-2.amazonaws.com/production/stack-enquiry/send`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams(params)
        })
            .then((res) => res.json())
            .then((response) => {
                console.log(response.data)
                if (response == 200) {
                    setFormSuccess(true)
                    setFormError(false)
                } else {
                    setFormSuccess(false)
                    setFormError(true)
                }
                setIsLoading(false)
                setName("")
                setEmail("")
                setPhone("")
                setInformation("")
                setMainStacks([])
                setSubStacks([])
            })
            .catch((err) => {
                console.log(err.message);
                setIsLoading(false)
                setFormSuccess(false)
                setFormError(true)
            });
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setFormSuccess(false)
            setFormError(false)
        }, 5000);

        return () => clearTimeout(timer); // Clean up the timer on component unmount or dependency change
    }, [setFormSuccess, setFormError]);

    return (
        <section className="techstacks-options-section">
            <div className="techstacks-options-content-container">
                <div className="techstacks-options-main-options-box">
                    <TechStacksMainOptions selectedOptions={mainStacks} onSelectionChange={handleMainSelectionChange} />
                </div>
                <TechStacksOptionsDropdown selectedOptions={subStacks} onSelectionChange={handleSubSelectionChange} />
                <form className="techstacks-options-sub-form" onSubmit={enquire}>
                    <h3 className="techstacks-options-sub-form-title">Your details</h3>

                    <div className="techstacks-options-input-container">
                        <label for="name">Name</label>
                        <input type="name" placeholder="Your name" onChange={(e) => setName(e.target.value)} required />
                    </div>
                    <div className="techstacks-options-input-container">
                        <label for="phone">Phone Number</label>
                        <input type="phone" placeholder="Your phone number" onChange={(e) => setPhone(e.target.value)} required />
                    </div>
                    <div className="techstacks-options-input-container">
                        <label for="email">Your email</label>
                        <input type="email" placeholder="Your email" onChange={(e) => setEmail(e.target.value)} required />
                    </div>
                    <div className="techstacks-options-input-container">
                        <label for="more_info">Tell us more</label>
                        <textarea type="text" onChange={(e) => setInformation(e.target.value)}></textarea>
                    </div>
                    {!isLoading ? (
                        <button type="submit">Enquire Now</button>
                    ) : (
                        <OrbitProgress color="#888" size="small" text="" textColor="" />
                    )}
                    {formSuccess && (<h2 className="techstacks-options-title" style={{ textAlign: "center" }}>Thanks! We'll be in touch soon</h2>)}
                    {formError && (<h2 className="techstacks-options-title" style={{ textAlign: "center" }}>Something went wrong, please try again.</h2>)}
                </form>

            </div>
        </section>
    )
}

export default TechStacksOptions;