import React from 'react';
import './index.css';

const CareerDetails = ({ listing }) => {
  const clickedApply = () => {
    window.open("https://forms.gle/ZRaDujgQNA1t1u576", "_blank")
  }
  return (
    <div className="career-details-container">
      <div className="career-details-content">
        <ul>
          {listing.responsibilities.map((responsibility, index) => (
            <li><span style={{ fontWeight: "bold" }}>{responsibility.title}:</span> {responsibility.description}</li>
          ))}
        </ul>
        <h2>Skills & Experience</h2>
        <ul>
          {listing.skills_experience.map((skill, index) => (
            <li><span style={{ fontWeight: "bold" }}>{skill.title}:</span> {skill.description}</li>
          ))}
        </ul>
        <p className="career-details-interested-title">Are you interested?</p>
        <button onClick={clickedApply} className="career-details-submit-button">Apply Now</button>
      </div>
    </div>
  );
}

export default CareerDetails;
