// NextSection.js
import React from 'react';
import './index.css'

function CareerHeroStepDown() {
    const clickedApply = () => {
        window.open("https://forms.gle/ZRaDujgQNA1t1u576", "_blank")
    }
    return (
        <section className="career-hero-stepdown">
            <div className="career-hero-stepdown-content-container">
                <div className="career-hero-stepdown-left-content">
                    <div className="career-hero-stepdown-left-content-container">
                    <p className="career-hero-stepdown-title">Responsibilities</p>
                    <p className="career-hero-stepdown-description">Some of the things you’ll do in this role…</p>
                    </div>
                </div>
                <div className="career-hero-stepdown-step-box"></div>
            </div>
            <button onClick={clickedApply} className="career-hero-submit-button">Apply Now</button>
        </section>
    );
}

export default CareerHeroStepDown;