import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import CompaniesPage from './Pages/CompaniesPage';
import TechStacks from './Pages/TechStacksPage';
import Careers from './Pages/CareersPage';
import Career from './Pages/CareerPage';
import Home from './Pages/Home';
import { AnimatePresence } from 'framer-motion';


function AnimatedRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/for-companies" element={<CompaniesPage />} />
        <Route path="/tech-stacks" element={<TechStacks />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/careers/:id" element={<Career />} />
      </Routes>
    </AnimatePresence>
  );
}

function App() {
  return (
    <div className="App">
      <Router>
      <AnimatedRoutes />
    </Router>
    </div>
  );
}

export default App;
library.add(fab, fas, far)