import { motion } from "framer-motion";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header"
import TechStacksHero from "./TechStacksHero";
import TechStacksHowItWorks from "./TechStacksHero/TechStacks-How-It-Works";
import TechStacksOptions from "./TechStacksOptions";
import { pageVariants, pageTransition } from '../../variants';
import { useNavigate } from "react-router-dom";

function TechStacks() {
    const navigate = useNavigate();

    const didClickSchedule = () => {
        navigate("/for-companies");
    }
    return (
        <>
            <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
            >
                <Header type={2} didClickSchedule={didClickSchedule}/>
                <TechStacksHero />
                <TechStacksOptions />
                <Footer />
            </motion.div>
        </>
    );
}

export default TechStacks;