import styles from './index.module.scss'

function HowWeDoIt() {
    return (
        <section className={styles.howwedoitSection}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <h2>Here's how we do it.</h2>
                </div>
                <div className={styles.container}>
                    <div className={styles.step}>
                        <div className={styles.tagline}>
                            <h1>1</h1>
                            <p>Tell us what you're working on</p>
                        </div>
                        <div className={styles.details}>
                            <ul>
                                <li>Fill us in on what you want to achieve</li>
                                <li>Decide or get advice on the details like tech stacks</li>
                            </ul>
                        </div>
                    </div>
                    <div className={styles.step}>
                        <div className={styles.tagline}>
                            <h1>2</h1>
                            <p>Let's schedule an intro call</p>
                        </div>
                        <div className={styles.details}>
                            <ul>
                                <li>We'll break down your work, requirements, and timelines</li>
                                <li>We'll make sure we're a good fit for each other</li>
                            </ul>
                        </div>
                    </div>
                    <div className={styles.step}>
                        <div className={styles.tagline}>
                            <h1>3</h1>
                            <p>Let the work begin</p>
                        </div>
                        <div className={styles.details}>
                            <ul>
                                <li>A kickoff call defining your timelines takes place and ongoing review sessions keep you in the loop with all key points of progress</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HowWeDoIt;